import { makeStyles } from "@material-ui/core";
import { adEditorCancelButton } from "../../shared/commonStyles";
export default makeStyles((theme) => ({
  containerOuter: {
    marginTop: 40,
    background: "#FFF",
    borderRadius: 8,
    padding: 40,
    display: "flex",
    justifyContent: "center",
    "& button:focus": {
      outline: 0
    }
  },
  container: {
    padding: "50px 120px",
    width: "100%"
  },
  stepSelect: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 40,
    width: "100%",
    margin: "auto"
  },
  payDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40
  },
  stepContainer: {
    display: "flex",
    flexDirection: "column",
    "& h4": {
      color: "#666666",
      fontSize: 20,
      fontWeight: "600",
      marginBottom: 0,
      marginTop: 70,
      "&:first-child": {
        marginTop: 0
      },
      "& span": {
        color: "#E37D7A"
      },
      "& + div": {
        marginTop: 20
      }
    },
    "& fieldset": {
      borderColor: "#DDDDDD"
    }
  },
  formControl: {
    marginTop: 50
  },
  subFormControl: {
    marginTop: 20
  },
  exLarge: {
    maxWidth: 800
  },
  large: {
    maxWidth: 600
  },
  medium: {
    width: 400
  },
  small: {
    width: 300
  },
  adProductInfo: {
    fontSize: 12,
    paddingTop: 10,
    textAlign: "center"
  },
  seekAdTypeDisabled: {
    justifyContent: "center",
    opacity: 0.5,
    cursor: "default",
    textAlign: "center",
    "& .seekAdheaderWrapper": {
      color: "white",
      height: "2rem",
      alignItems: "center",
      backgroundColor: "#E60278",
      display: "flex",
      justifyContent: "center",
      fontSize: "1rem",
      padding: "1.3rem 0 1.3rem 0"
    },
    "& h4": {
      marginTop: "0.1rem",
      textAlign: "center",
      marginBottom: "-10px",
      color: "black"
    },
    "& div": {
      textAlign: "center"
    },
    "& .seekRadioRadioBtnWrapper": {
      marginTop: "1rem",
      "& span:nth-child(1)": {
        margin: "auto",
        display: "flex",
        width: "2.5rem"
      }
    }
  },
  seekAdType: {
    justifyContent: "center",
    cursor: "pointer",
    textAlign: "center",
    height: "100%",
    "& .seekAdheaderWrapper": {
      color: "white",
      height: "2rem",
      alignItems: "center",
      backgroundColor: "#E60278",
      display: "flex",
      justifyContent: "center",
      fontSize: "1rem",
      padding: "1.3rem 0 1.3rem 0"
    },
    "& h4": {
      marginTop: "0.1rem",
      textAlign: "center",
      marginBottom: "-10px",
      color: "black"
    },
    "& div": {
      textAlign: "center"
    },
    "& .seekRadioRadioBtnWrapper": {
      marginTop: "1rem",
      "& span:nth-child(1)": {
        margin: "auto",
        display: "flex",
        width: "2.5rem"
      }
    }
  },
  seekEmbeddedHTML: {
    padding: "1rem 0.2rem 0 0.2rem",
    "& ul": {
      listStyle: "disc",
      listStylePosition: "inside",
      margin: "1rem 0.4rem",
      paddingLeft: "1rem"
    }
  },
  checkboxWrapper: {
    color: "#939393"
  },
  greyBox: {
    background: "#FAFAFA",
    border: "1px solid #DDDDDD",
    borderRadius: 6,
    padding: 30,
    "& span": {
      color: "#939393",
      fontSize: 16,
      display: "block",
      marginRight: 8
    },
    "& > div": {
      marginBottom: 15,
      "&:last-child": {
        marginBottom: 0
      }
    }
  },
  applicationSettingsInner: {
    "& > div": {
      fontSize: 16,
      color: "#000",
      textAlign: "right",
      "&:first-child": {
        fontSize: 18,
        color: "#939393",
        textAlign: "left"
      }
    },
    "& span": {
      display: "block",
      color: "#000"
    }
  },
  greyIcon: {
    color: theme.palette.secondary.main,
    width: 18,
    height: 18
  },
  greyInput: {
    padding: "0 32px 0 0 !important",
    color: "#000000",
    fontSize: 16,
    "&:focus": {
      background: "#FAFAFA"
    }
  },
  inputField: {
    padding: "10px 18px",
    borderRadius: 6,
    border: "1px solid #DDDDDD",
    background: "#FFF"
  },
  textField: {
    "& input": {
      borderRadius: 6
    }
  },
  dropdown: {
    "& input": {
      borderRadius: 6
    }
  },
  textBox: {
    "& input": {
      height: 150
    }
  },
  controlledNumberInput: {
    "& fieldset": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    "& button": {
      color: "#A2A2A2",
      borderRadius: 0,
      border: "1px solid #DDDDDD",
      borderLeft: 0,
      padding: 0,
      minWidth: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:last-child": {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6
      },
      "&[disabled]": {
        background: "#F3F3F3",
        color: "#E5E5E5"
      }
    }
  },
  fullWidth: {
    width: "100%"
  },
  input: {
    padding: "10px 18px",
    borderRadius: 6,
    width: "100%",
    "&:focus": {
      background: "#FFF"
    }
  },
  inputAdornment: {
    "& input": {
      paddingLeft: 0
    },
    "& span": {
      fontSize: 16
    }
  },
  placeholder: {
    fontSize: 14,
    color: "#CCCCCC"
  },
  salaryInput: {
    width: "31%",
    "& > div:first-child": {
      width: "100%"
    }
  },
  label: {
    color: "#939393",
    fontSize: 16,
    marginBottom: 14,
    display: "flex",
    alignItems: "center",
    lineHeight: "20px",
    "& span": {
      color: "#E37D7A"
    }
  },
  asterisk: {
    display: "block",
    marginLeft: 3,
    color: theme.palette.error.main
  },
  errorAutocomplete: {
    "& > div:first-child": {
      borderColor: theme.palette.error.main
    }
  },
  errorDescription: {
    borderColor: `${theme.palette.error.main} !important`
  },
  subLabel: {
    display: "block",
    color: "#000",
    fontSize: 14,
    marginBottom: 10
  },
  salaryIntervalLabel: {
    width: 150
  },
  helperText: {
    textAlign: "right",
    position: "absolute",
    bottom: "-22px",
    right: 0
  },
  calendarContainer: {
    marginTop: 10,
    "& input": {
      padding: "10px 18px",
      borderRadius: 6,
      border: "1px solid #DDD",
      width: "100%",
      "&:focus": {
        background: "#FFF",
        outline: 0,
        borderColor: theme.palette.primary.main
      }
    },
    "& .DayPicker": {
      background: "#FFF",
      borderRadius: 6,
      boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
      position: "absolute",
      zIndex: 100
    },
    "& .DayPicker-wrapper": {
      padding: "20px 10px",
      borderRadius: 6
    },
    "& .DayPicker-Caption": {
      height: "2rem",
      "& div": {
        fontWeight: "600",
        fontSize: 18,
        color: theme.palette.primary.main
      }
    },
    "& .DayPicker-NavBar": {
      top: "1.3rem",
      "& span": {
        height: "0.9rem",
        width: "0.9rem"
      }
    },
    "& .DayPicker-Weekdays abbr": {
      textDecoration: "none"
    },
    "& .DayPicker-Day": {
      border: 0,
      padding: "10px 12px",
      fontSize: 12
    },
    "& .DayPicker-Day--today": {
      color: theme.palette.primary.main,
      fontWeight: "600"
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "100%"
    }
  },
  actionText: {
    fontWeight: "600",
    color: theme.palette.primary.main,
    fontSize: 14,
    display: "block",
    marginBottom: 10,
    "&:hover": {
      cursor: "pointer"
    }
  },
  spaceItems: {
    "& > *": {
      marginBottom: 14
    }
  },
  removeIcon: {
    position: "absolute",
    right: 10,
    color: "#DDD",
    "&:hover": {
      color: "#E37D7A",
      cursor: "pointer"
    }
  },
  sliderContainer: {
    width: 560,
    marginTop: 15,
    borderRadius: 6,
    border: "1px solid #DDDDDD",
    padding: "30px 60px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center"
  },
  sliderTitle: {
    color: theme.palette.primary.main,
    fontWeight: "600",
    display: "block",
    marginBottom: 35
  },
  sliderValue: {
    display: "block",
    marginTop: 4,
    color: "#999",
    textAlign: "right"
  },
  thumb: {
    width: 20,
    height: 20,
    marginTop: "-9px"
  },
  textEditor: {
    borderRadius: 6,
    border: "1px solid #DDDDDD",
    overflow: "hidden"
  },
  textEditorToolbar: {
    display: "flex",
    flexDirection: "row",
    background: "#DDDDDD",
    padding: "10px 15px 6px",
    "& .rdw-inline-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-list-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-text-align-wrapper": {
      display: "flex",
      flexDirection: "row"
    },
    "& .rdw-link-wrapper": {
      display: "flex",
      flexDirection: "row",
      "& .rdw-link-modal": {
        display: "grid",
        position: "absolute",
        background: "lightgrey",
        padding: "10px",
        marginTop: "1.75rem",
        "& label": {
          padding: "10px 0"
        },
        "& button": {
          marginRight: "1rem"
        },
        "& #openLinkInNewWindow": {
          marginRight: "0.5rem"
        }
      }
    },
    "& .rdw-option-wrapper": {
      padding: "6px 10px 0px 10px",
      height: "1.75rem",
      backgroundColor: "transparent",
      cursor: "pointer"
    },
    "& .rdw-option-active ": {
      backgroundColor: "darkgrey"
    }
  },
  textEditorBody: {
    minHeight: 250,
    padding: 20,
    "&:hover": {
      cursor: "text"
    },
    "& li": {
      listStyle: "disc"
    },
    "& .rdw-center-aligned-block *": {
      textAlign: "center !important"
    },
    "& .rdw-left-aligned-block *": {
      textAlign: "left !important"
    },
    "& .rdw-right-aligned-block *": {
      textAlign: "right !important"
    }
  },
  stepperRoot: {
    width: "100%",
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  stepItem: {
    fontWeight: "600"
  },
  stepIconActive: {
    color: `${theme.palette.secondary.main} !important`,
    boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
    "& text": {
      color: "#FFF"
    }
  },
  stepIconCompleted: {
    color: `${theme.palette.secondary.main} !important`,
    boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
    "& text": {
      color: "#FFF"
    }
  },
  stepIconRoot: {
    color: "#F3F3F3",
    borderRadius: "100%",
    width: "50px",
    height: "50px",
    "& text": {
      color: "#CCCCCC"
    }
  },
  stepLabelIconContainer: {
    width: "50px",
    height: "50px"
  },
  stepLabelLabel: {
    fontWeight: "600 !important",
    color: "#CCCCCC !important"
  },
  stepLabelActive: {
    color: "#666666 !important"
  },
  stepLabelCompleted: {
    color: "#666666 !important"
  },
  stepHover: {
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8
    }
  },
  stepNoHover: {
    "&:hover": {
      cursor: "auto"
    }
  },
  buttonContainer: {
    marginTop: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& a:not(.preview)": adEditorCancelButton
  },
  buttons: {
    display: "flex",
    "& > *": {
      marginLeft: 20
    }
  },
  previewType: {
    display: "flex",
    marginTop: 20,
    borderBottom: "3px solid #F3F3F3",
    position: "relative",
    width: 260,
    "& div": {
      padding: 20,
      fontSize: 16,
      color: "#939393",
      flexGrow: 1,
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        color: "#666"
      }
    },
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "50%",
      background: "#2557A7",
      borderRadius: 3,
      left: "0%",
      bottom: "-3px",
      height: 3,
      transition: "left 0.2s ease"
    },
    "&.mobile:after": {
      left: "50%"
    }
  },
  previewContainer: {
    border: "1px solid #DDD",
    borderRadius: 6,
    padding: "40px 50px",
    marginTop: 20,
    marginBottom: 60,
    width: "80%",
    "&.mobile": {
      width: "40%"
    }
  },
  previewTitle: {
    color: "#666666",
    fontSize: 30,
    fontWeight: "600"
  },
  previewDetails: {
    fontSize: 20,
    color: "#939393",
    marginTop: 6
  },
  previewButtons: {
    display: "flex",
    marginTop: 30,
    "& div": {
      borderRadius: 6,
      fontWeight: "600",
      lineHeight: "20px",
      "&:hover": {
        cursor: "pointer"
      }
    },
    "& div:first-child": {
      background: "#2557A7",
      color: "#FFF",
      fontSize: 20,
      marginRight: 10,
      padding: "9px 24px",
      "&:hover": {
        background: "#3d6fbf"
      }
    },
    "& div:last-child": {
      color: "#606060",
      background: "#F3F3F3",
      padding: "9px 12px",
      "&:hover": {
        background: "#e6e3e3"
      }
    }
  },
  previewContent: {
    marginTop: 30,
    "& p, li": {
      fontSize: 18,
      color: "#939393",
      marginTop: 0
    },
    "& strong": {
      fontWeight: "600",
      color: "#666666",
      fontSize: 20
    },
    "& ul": {
      listStyle: "disc",
      paddingLeft: 20
    },
    "& li:marker": {
      color: "#939393"
    }
  },
  applicationSettings: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 25,
    "& span": {
      color: theme.palette.primary.main,
      fontWeight: "600",
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  dialog: {
    padding: "40px 60px",
    alignItems: "center",
    textAlign: "center",
    "& > h4": {
      fontSize: 26,
      color: theme.palette.primary.main,
      marginBottom: 14
    },
    "& > span": {
      fontWeight: "600",
      color: "#666666",
      fontSize: 16,
      lineHeight: "20px"
    },
    "& button:focus": {
      outline: 0
    }
  },
  dialogButtonContainer: {
    display: "flex",
    marginTop: 40,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "& > button": {
      margin: "0 10px",
      minWidth: 150
    }
  },
  successDialog: {
    padding: "60px 60px",
    alignItems: "center",
    textAlign: "center",
    "& svg": {
      width: 24,
      height: 25,
      "& path": {
        fill: "white",
        width: "100%",
        height: "100%"
      }
    },
    "& > h4": {
      marginTop: 0,
      fontSize: 45,
      color: "#666666",
      marginBottom: 4,
      fontWeight: "600"
    },
    "& > span": {
      fontWeight: "500",
      color: "#666666",
      fontSize: 24,
      display: "block",
      marginBottom: 20
    }
  },
  failDialog: {
    padding: "60px 60px",
    alignItems: "center",
    textAlign: "center",
    "& svg": {
      width: 90,
      height: 90,
      "& path": {
        fill: "#E60278",
        width: "100%",
        height: "100%"
      }
    },
    "& > h4": {
      marginTop: 10,
      fontSize: 31,
      color: theme.palette.primary.main,
      marginBottom: 4,
      fontWeight: "600"
    },
    "& > span": {
      fontWeight: "500",
      color: "#666666",
      fontSize: 18,
      display: "block",
      marginBottom: 20
    }
  },
  successCheck: {
    position: "relative",
    "& svg": {
      width: 64,
      height: 64,
      "& path": {
        fill: "#E60278",
        width: "100%",
        height: "100%"
      }
    },
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      border: "10px solid #E60278",
      borderRadius: "100%",
      boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.3)"
    }
  },
  autoComplete: {
    marginTop: 10,
    "& .MuiInputBase-root": {
      padding: "0 6px !important",
      "& input::placeholder": {
        fontSize: 14,
        color: "#CCCCCC",
        opacity: 1
      }
    }
  },
  autoCompletePopper: {
    "& fieldset": {
      padding: "10px 18px",
      borderRadius: 6,
      border: "1px solid #DDDDDD"
    },
    "& svg": {
      fill: "#DDD",
      marginRight: 10
    }
  },
  error: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    color: theme.palette.error.main,
    left: "25px",
    position: "relative",
    width: 0,
    "& span": {
      position: "absolute",
      whiteSpace: "nowrap",
      left: 30,
      fontSize: 13,
      fontWeight: "400"
    },
    "& svg": {
      "&:hover": {
        cursor: "auto"
      }
    }
  },
  errorOutline: {
    "& $error": {
      left: 120
    },
    "& fieldset": {
      borderLeft: `1px solid ${theme.palette.error.main}`,
      borderTop: `1px solid ${theme.palette.error.main}`,
      borderBottom: `1px solid ${theme.palette.error.main}`
    },
    "& button": {
      borderTop: `1px solid ${theme.palette.error.main}`,
      borderBottom: `1px solid ${theme.palette.error.main}`,
      "&:last-child": {
        borderRight: `1px solid ${theme.palette.error.main}`
      }
    }
  },
  successDialogContainer: {
    marginTop: "20px",
    "& button": {
      padding: "9px 13px"
    }
  },
  successDialogText: {
    display: "flex",
    alignItems: "center",
    height: "3rem",
    fontWeight: "600",
    color: "#666666",
    "& span": {
      paddingRight: "0.5rem"
    }
  },
  errorMessage: {
    "& svg": {
      margin: "auto 0",
      fontSize: "15px",
      marginRight: "0.5rem"
    }
  },
  reviewQuestionText: {
    textDecoration: "underline",
    marginTop: "0.5rem",
    cursor: "pointer"
  },
  seekYoutubeUrl: {
    color: "red",
    marginLeft: "1.75rem"
  },
  optionHint: {
    marginTop: "1rem",
    border: "1px solid #ddd",
    padding: "1.5em 1em",
    boxShadow: "#ccc 0 0 9px 1px",
    maxWidth: "400px",
    fontSize: "0.9rem"
  },
  secondaryRadioGroup: {
    marginLeft: "1rem"
  },
  secondaryRadio: {
    "& span": {
      fontSize: "0.9rem"
    }
  },
  buttonSecondary: {
    borderRadius: 6,
    padding: "14px 30px",
    color: "#FFF !important",
    textDecoration: "none !important",
    fontWeight: "600",
    fontSize: 14,
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.dark
    }
  },
  buttonSecondaryLight: {
    borderRadius: 6,
    padding: "14px 30px",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  subheading: {
    fontSize: "19.4px",
    marginTop: "0px"
  },
  closeModalContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center"
  }
}));
