"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState } from "react";
import Api from "../Job/API";
export const useApplicationsForTask = () => {
  const [applications, setApplications] = useState([]);
  const fetchApplications = (jobId) => __async(void 0, null, function* () {
    let page = 1;
    let totalPages = 9999;
    let results = [];
    while (page <= totalPages) {
      const response = yield Api.getApplicationsForTask(jobId, page);
      results = [...results, ...response.data.applications];
      if (page === 1) {
        setApplications(results);
      }
      totalPages = response.totalPages;
      page += 1;
    }
    if (page > 1) {
      setApplications(results);
    }
  });
  return { applications, fetchApplications };
};
