import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Application from './Application';

export interface IApplicationPageProps {
  applicationId: number;
  jobId: number;
  showLinkedInURL: boolean;
  aiSummariesEnabled: boolean;
  currentUser: { id: number; api_key: string; admin: boolean; timeZone: string; email: string };
  newIntegrationEnabled: boolean;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function ApplicationPage({
  applicationId,
  jobId,
  showLinkedInURL,
  aiSummariesEnabled,
  currentUser,
  newIntegrationEnabled
}: IApplicationPageProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Application
        {...{
          applicationId,
          jobId,
          showLinkedInURL,
          aiSummariesEnabled,
          currentUser,
          newIntegrationEnabled
        }}
      />
    </QueryClientProvider>
  );
}
