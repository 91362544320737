import { makeStyles } from "@material-ui/core";
import {
  SCOUT_SECONDARY_BLUE,
  SCOUT_SECONDARY_LIGHT_BLUE,
  RED,
  DARK_RED,
  WHITE,
  SCOUT_BLUE
} from "../../ThemeContext/constants";
export default makeStyles(() => ({
  dialog: {
    padding: "40px 60px",
    alignItems: "center",
    textAlign: "center"
  },
  dialogHeader: {
    fontSize: "26px",
    color: SCOUT_BLUE,
    marginTop: 0,
    marginBottom: 14
  },
  dialogDescription: {
    fontWeight: "600",
    color: "#666666",
    fontSize: "16px",
    lineHeight: "20px",
    margin: 0
  },
  dialogButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 40
  },
  buttonSecondaryLight: {
    borderRadius: 6,
    padding: "14px 30px",
    marginRight: 20,
    fontSize: "15px !important",
    background: `${SCOUT_SECONDARY_LIGHT_BLUE} !important`,
    color: `${SCOUT_SECONDARY_BLUE} !important`,
    "&:hover": {
      background: `${SCOUT_SECONDARY_BLUE} !important`,
      color: `${SCOUT_SECONDARY_LIGHT_BLUE} !important`
    }
  },
  buttonSecondary: {
    borderRadius: 6,
    padding: "14px 30px",
    fontSize: "15px !important",
    background: `${RED} !important`,
    color: `${WHITE} !important`,
    "&:hover": {
      background: `${DARK_RED} !important`
    }
  },
  closeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%"
  }
}));
