import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useDialogStyles from "../useDialogStyles";
export function ExitExistingDialogContent({ saving, savingDraft, close, saveDraft }) {
  const classes = useDialogStyles();
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: classes.closeContainer }, /* @__PURE__ */ React.createElement(IconButton, { onClick: close }, /* @__PURE__ */ React.createElement(CloseIcon, { style: { fill: "#cccccc", width: 20, height: 20 } }))), /* @__PURE__ */ React.createElement("h4", { className: classes.dialogHeader }, "Changes are not yet live!"), /* @__PURE__ */ React.createElement("p", { className: classes.dialogDescription }, "Your changes are not saved yet."), /* @__PURE__ */ React.createElement("p", { className: classes.dialogDescription }, `Please navigate to the last page and click 'Update' to ensure all edits to your SEEK Ad are saved.`), /* @__PURE__ */ React.createElement("div", { className: classes.dialogButtonContainer }, /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: classes.buttonSecondaryLight,
      onClick: saveDraft,
      style: { minWidth: 130 }
    },
    savingDraft ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : "No, save and exit"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: classes.buttonSecondary,
      id: "SeekNextStepMultiposterBtn",
      style: { minWidth: 130 },
      onClick: close,
      endIcon: saving ? null : /* @__PURE__ */ React.createElement(ChevronRightIcon, null)
    },
    "Continue Editing"
  )));
}
ExitExistingDialogContent.propTypes = {
  saving: PropTypes.bool,
  savingDraft: PropTypes.bool,
  close: PropTypes.func,
  saveDraft: PropTypes.func
};
export default ExitExistingDialogContent;
