import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";
import useDialogStyles from "../useDialogStyles";
export function ExitNewDialogContent({ savingDraft, close, saveDraft }) {
  const classes = useDialogStyles();
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h4", { className: classes.dialogDescription }, "Are you sure?"), /* @__PURE__ */ React.createElement("div", { className: classes.dialogButtonContainer }, /* @__PURE__ */ React.createElement(Button, { disableElevation: true, className: classes.buttonSecondaryLight, onClick: close }, "Cancel"), /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: classes.buttonSecondary,
      onClick: saveDraft,
      style: { minWidth: 130 }
    },
    savingDraft ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : "Save and exit"
  )));
}
ExitNewDialogContent.propTypes = {
  savingDraft: PropTypes.bool,
  close: PropTypes.func,
  saveDraft: PropTypes.func
};
export default ExitNewDialogContent;
