var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { usePreviewSeekAd } from "../../SeekAd/hooks/usePreviewSeekAd";
import { withStyles } from "@material-ui/core/styles";
import { withScoutTheme } from "../../ThemeContext/ThemeContext";
import { Step1, Step2, Step3, Step4 } from "./Steps";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Dialog,
  Snackbar,
  CircularProgress
} from "@material-ui/core";
import useStyles from "./useStyles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import axios from "axios";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ExitConfirmationDialog from "./Components/ExitConfirmationDialog";
const steps = [
  { label: "Classify Job", value: "classify_job" },
  { label: "What Jobseekers See In Search Results", value: "search_results" },
  { label: "Ad Description", value: "description" },
  { label: "Preview", value: "preview" },
  { label: "Publish", value: "publish" }
];
function AdEditor({
  jobAdId,
  apiKey,
  jobId,
  linkOutEnabled,
  privacyOverLimit,
  seek_billing_reference,
  seek_billing_reference_flag,
  job_url_prop
}) {
  const [saving, setSaving] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [brandingEnabled, setBrandingEnabled] = useState(false);
  const [maxBulletPoints, setMaxBulletPoints] = useState(0);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [failedSeekDialogOpen, setFailedSeekDialogOpen] = useState(false);
  const [failedSeekDialogMessage, setfailedSeekDialogMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [getSeekAdBrandingData, setGetSeekAdBrandingData] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [activeError, setActiveError] = useState(null);
  const [activeErrorMessage, setActiveErrorMessage] = useState(null);
  const [editExistingJob, setEditExistingJob] = useState(false);
  const classes = useStyles();
  const { previewUrl } = usePreviewSeekAd(jobAdId);
  const QontoConnector = withStyles((theme) => ({
    alternativeLabel: {
      top: 25,
      left: "calc(-50% + 25px)",
      right: "calc(50% + 25px)"
    },
    active: {
      "& $line": {
        borderColor: theme.palette.secondary.main
      }
    },
    completed: {
      "& $line": {
        borderColor: theme.palette.secondary.main
      }
    },
    line: {
      borderColor: "#F3F3F3",
      borderTopWidth: 3,
      borderRadius: 1
    }
  }))(StepConnector);
  function submit(type) {
    return __async(this, null, function* () {
      try {
        type === "draft" ? setSavingDraft(true) : setSaving(true);
        yield axios.put(
          `/api/v4/seek/job_ads/${jobAdId}`,
          { job_ad: answers, draft: type === "draft" ? true : false },
          {
            headers: { "X-api-authenticate": apiKey }
          }
        );
        if (activeStep === 3 && type !== "draft") {
          try {
            answers.advertisement_id ? yield axios.put(`/api/v4/seek/job_ads/${jobAdId}/ad_posting`, null, {
              headers: { "X-api-authenticate": apiKey }
            }) : yield axios.post(`/api/v4/seek/job_ads/${jobAdId}/ad_posting`, null, {
              headers: { "X-api-authenticate": apiKey }
            });
            setIsSuccessDialogOpen(true);
          } catch (e) {
            setFailedSeekDialogOpen(true);
            return;
          }
        }
        if (type === "draft") {
          handleSnack("Draft saved");
          setIsExitModalOpen(false);
          window.location.href = `/admin/jobs/${jobId}/job_ads`;
        } else if (type === "submit") {
          setIsSuccessDialogOpen(true);
        } else if (type === "next") {
          setActiveStep(activeStep + 1);
        }
      } catch (e) {
        if (type === "draft") {
          handleSnack("Unable to save draft");
        } else if (type === "submit") {
          handleSnack("Unable to post to SEEK. Please check required fields and try again.");
        } else if (type === "next") {
          setActiveStep(activeStep + 1);
        }
      } finally {
        setSaving(false);
        setSavingDraft(false);
      }
    });
  }
  function handleSnack(message) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }
  function getJobData() {
    return __async(this, null, function* () {
      try {
        const { data } = yield axios.get(`/api/v4/seek/job_ads/${jobAdId}`, {
          headers: { "X-api-authenticate": apiKey }
        });
        setAnswers(data);
        setEditExistingJob(data.advertisement_id && data.advertisement_published_at ? true : false);
        setActiveStep(0);
      } catch (e) {
      }
    });
  }
  useEffect(() => {
    getJobData();
  }, []);
  useEffect(() => {
    setActiveError(null);
  }, [answers]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeStep]);
  const requiredRefs = {
    title: useRef(null),
    listing_location: useRef(null),
    category: useRef(null),
    salary: useRef(null),
    search_title: useRef(null),
    description: useRef(null),
    ad_details: useRef(null),
    link_out: useRef(null),
    video_embed_code: useRef(null),
    ad_type: useRef(null)
  };
  function setError(data) {
    setActiveError(requiredRefs[Object.keys(data)[0]]);
    setActiveErrorMessage(Object.values(data)[0]);
  }
  function handleNext(type) {
    return __async(this, null, function* () {
      const errorRef = checkErrors();
      if (errorRef) {
        const ref = requiredRefs[errorRef];
        setActiveError(ref);
        window.scrollTo({ top: ref.current.offsetTop - 120, behavior: "smooth" });
      } else {
        try {
          const { data } = yield axios.post(
            `/api/v4/seek/job_ads/${jobAdId}/validate`,
            { job_ad: __spreadProps(__spreadValues({}, answers), { current_step: steps[activeStep].value }) },
            {
              headers: { "X-api-authenticate": apiKey }
            }
          );
          if (data.success) {
            submit(type);
            if (activeError) {
              setActiveError(null);
              setActiveErrorMessage(null);
            }
          } else {
            setFailedSeekDialogOpen(true);
            setfailedSeekDialogMessage(data.error_message);
          }
        } catch (e) {
          return e;
        }
      }
    });
  }
  function checkErrors() {
    const error = Object.keys(requiredRefs).find((ref) => {
      if (activeStep === 0) {
        if (["title", "listing_location"].includes(ref)) {
          return !answers[ref] && ref;
        } else if (ref === "category") {
          return answers.use_suggested_classification ? !answers.listing_sub_classification && ref : !answers.listing_sub_classification && !answers.listing_classification && ref;
        } else if (ref === "salary") {
          let difference = answers.salary_max - answers.salary_min;
          return !answers.salary_min || !answers.salary_max || answers.salary_min < 1e5 && difference > 1e5 && ref;
        } else if (ref === "ad_type") {
          return !answers[ref] && ref;
        }
      } else if (activeStep === 1) {
        if (["description", "search_title"].includes(ref)) {
          return !answers[ref] && ref;
        } else if (ref === "video_embed_code" && answers.video_embed_code !== "") {
          if (!answers.video_embed_code) return;
          const regex = /https:\/\/((www\.youtube\.com\/embed|youtu\.be)\/[\w-]+(\?[\w-]+=[\w-]*(&[\w-]+=[\w-]*)*)?|www\.youtube\.com\/watch\?v=[\w-]+(&[\w-]+=[\w-]*)*)/;
          return !answers.video_embed_code.match(regex) && ref;
        }
      } else if (activeStep === 2) {
        if (["title", "ad_details"].includes(ref)) {
          return !answers[ref] && ref;
        }
      }
    });
    return error;
  }
  const buttonName = () => {
    if (activeStep + 2 >= steps.length) {
      return editExistingJob ? "Update" : "Publish";
    } else {
      return "Next";
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: classes.containerOuter }, /* @__PURE__ */ React.createElement("div", { className: classes.container }, /* @__PURE__ */ React.createElement("div", { className: classes.stepSelect }, /* @__PURE__ */ React.createElement(
    Stepper,
    {
      classes: { root: classes.stepperRoot },
      alternativeLabel: true,
      activeStep,
      connector: /* @__PURE__ */ React.createElement(QontoConnector, null)
    },
    steps.map((step, i) => /* @__PURE__ */ React.createElement(Step, { key: i, classes: { root: `${classes.stepItem}` } }, /* @__PURE__ */ React.createElement(
      StepLabel,
      {
        classes: {
          iconContainer: classes.stepLabelIconContainer,
          label: classes.stepLabelLabel,
          active: classes.stepLabelActive,
          completed: classes.stepLabelCompleted
        },
        StepIconProps: {
          classes: {
            root: classes.stepIconRoot,
            active: classes.stepIconActive,
            completed: classes.stepIconCompleted
          }
        }
      },
      step.label
    )))
  )), answers && activeStep === 0 && /* @__PURE__ */ React.createElement(
    Step1,
    {
      answers,
      setAnswers,
      apiKey,
      jobId,
      jobAdId,
      requiredRefs,
      activeError,
      setBrandingEnabled,
      setMaxBulletPoints,
      seek_billing_reference,
      seek_billing_reference_flag
    }
  ), answers && activeStep === 1 && /* @__PURE__ */ React.createElement(
    Step2,
    {
      answers,
      setAnswers,
      getSeekAdBrandingData,
      setGetSeekAdBrandingData,
      apiKey,
      jobAdId,
      requiredRefs,
      activeError,
      activeErrorMessage,
      maxBulletPoints,
      brandingEnabled
    }
  ), answers && activeStep === 2 && /* @__PURE__ */ React.createElement(
    Step3,
    {
      setActiveStep,
      answers,
      setAnswers,
      requiredRefs,
      activeError,
      linkOutEnabled,
      privacyOverLimit,
      activeErrorMessage,
      jobId,
      setActiveError,
      setActiveErrorMessage,
      job_url_prop
    }
  ), answers && (activeStep === 3 || activeStep === 4) && /* @__PURE__ */ React.createElement(Step4, { answers }), /* @__PURE__ */ React.createElement("div", { className: classes.buttonContainer }, /* @__PURE__ */ React.createElement("a", { onClick: () => setIsExitModalOpen(true) }, "Save and exit"), /* @__PURE__ */ React.createElement("div", { className: classes.buttons }, activeStep !== 0 && /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: classes.buttonSecondaryLight,
      onClick: () => {
        setAnswers(__spreadProps(__spreadValues({}, answers), { current_step: steps[activeStep - 1].value }));
        setActiveStep(activeStep - 1);
      }
    },
    /* @__PURE__ */ React.createElement(ChevronLeftIcon, null)
  ), activeStep === 3 && /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: `${classes.buttonSecondary} preview`,
      style: { minWidth: 130 },
      href: previewUrl,
      target: "_blank"
    },
    "Preview on SEEK"
  ), activeStep !== 4 && /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: classes.buttonSecondary,
      id: "SeekNextStepMultiposterBtn",
      style: { minWidth: 130 },
      onClick: () => handleNext(activeStep + 1 === steps.length ? "submit" : "next"),
      endIcon: saving ? null : /* @__PURE__ */ React.createElement(ChevronRightIcon, null)
    },
    saving ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : buttonName()
  )))), /* @__PURE__ */ React.createElement(
    ExitConfirmationDialog,
    {
      saving,
      savingDraft,
      setActiveStep,
      isExitModalOpen,
      setIsExitModalOpen,
      editExistingJob,
      submit
    }
  ), /* @__PURE__ */ React.createElement(
    Dialog,
    {
      open: isSuccessDialogOpen,
      onClose: () => window.location.replace(document.referrer),
      classes: { paper: classes.successDialog }
    },
    /* @__PURE__ */ React.createElement("div", { className: classes.successCheck }, /* @__PURE__ */ React.createElement(CheckCircleIcon, null)),
    /* @__PURE__ */ React.createElement("h4", null, "Success!"),
    /* @__PURE__ */ React.createElement("div", { className: classes.successDialogText }, answers && answers.advertisement_id ? /* @__PURE__ */ React.createElement("span", null, "Your ad has been updated on SEEK") : /* @__PURE__ */ React.createElement("span", null, "Your ad has been posted to SEEK")),
    /* @__PURE__ */ React.createElement("div", { className: `${classes.dialogButtonContainer} ${classes.successDialogContainer}` }, /* @__PURE__ */ React.createElement(
      Button,
      {
        disableElevation: true,
        className: classes.buttonSecondary,
        onClick: () => {
          location.href = `/admin/jobs/${jobId}/job_ads`;
        },
        endIcon: /* @__PURE__ */ React.createElement(ChevronRightIcon, null)
      },
      "Back to ads"
    ))
  ), /* @__PURE__ */ React.createElement(
    Dialog,
    {
      open: failedSeekDialogOpen,
      onClose: () => {
        setFailedSeekDialogOpen(false);
        setfailedSeekDialogMessage("");
      },
      classes: { paper: classes.failDialog }
    },
    /* @__PURE__ */ React.createElement("h4", null, "Posting Failed"),
    /* @__PURE__ */ React.createElement("span", null, failedSeekDialogMessage || "Please check all your details again and republish."),
    /* @__PURE__ */ React.createElement("div", { className: classes.dialogButtonContainer }, /* @__PURE__ */ React.createElement(
      Button,
      {
        disableElevation: true,
        className: classes.buttonSecondary,
        onClick: () => {
          setFailedSeekDialogOpen(false);
          setfailedSeekDialogMessage("");
          setActiveStep(0);
        }
      },
      /* @__PURE__ */ React.createElement("span", { style: { marginRight: "0.5rem" } }, "Ok"),
      " >"
    ))
  ), /* @__PURE__ */ React.createElement(Snackbar, { open: isSnackOpen, onClose: () => setIsSnackOpen(false), message: snackMessage }));
}
AdEditor.propTypes = {
  jobAdId: PropTypes.number.isRequired,
  jobId: PropTypes.number.isRequired,
  apiKey: PropTypes.string.isRequired,
  linkOutEnabled: PropTypes.bool.isRequired,
  privacyOverLimit: PropTypes.bool,
  seek_billing_reference: PropTypes.bool,
  seek_billing_reference_flag: PropTypes.bool,
  job_url_prop: PropTypes.string
};
export default withScoutTheme(AdEditor);
