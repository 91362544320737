import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import useDialogStyles from "../useDialogStyles";
import ExitExistingDialogContent from "./ExitExistingDialogContent";
import ExitNewDialogContent from "./ExitNewDialogContent";
export function ExitConfirmationDialog({
  saving,
  savingDraft,
  isExitModalOpen,
  setIsExitModalOpen,
  editExistingJob,
  submit
}) {
  const classes = useDialogStyles();
  const close = () => setIsExitModalOpen(false);
  const saveDraft = () => submit("draft");
  return /* @__PURE__ */ React.createElement(Dialog, { open: isExitModalOpen, onClose: close, classes: { paper: classes.dialog } }, editExistingJob ? /* @__PURE__ */ React.createElement(
    ExitExistingDialogContent,
    {
      saving,
      savingDraft,
      close,
      saveDraft
    }
  ) : /* @__PURE__ */ React.createElement(ExitNewDialogContent, { savingDraft, close, saveDraft }));
}
ExitConfirmationDialog.propTypes = {
  saving: PropTypes.bool,
  savingDraft: PropTypes.bool,
  editExistingJob: PropTypes.bool,
  isExitModalOpen: PropTypes.bool,
  setActiveStep: PropTypes.func,
  setIsExitModalOpen: PropTypes.func,
  submit: PropTypes.func
};
export default ExitConfirmationDialog;
