"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export default class RecruitReportApiService {
  constructor(accessibleEntities, user) {
    this.accessibleEntities = [];
    this.headers = null;
    this.BASE_URL = window.location.origin;
    this.fetchReport = (reportType, format, params, filters) => __async(this, null, function* () {
      const payload = this.createPayload(reportType, params, filters);
      const response = yield fetch(`${this.BASE_URL}/api/reporting/${reportType}.${format}`, {
        headers: this.headers,
        method: "POST",
        body: JSON.stringify(payload)
      });
      return format === "json" ? yield response.json() : yield response.blob();
    });
    this.accessibleEntities = accessibleEntities;
    this.headers = {
      "Content-Type": "application/json",
      "X-api-authenticate": user.api_key
    };
  }
  createPayload(reportType, params, filters = {}) {
    return {
      [reportType]: {
        date_range: {
          start_date: params.start_date,
          end_date: params.end_date
        },
        entity_ids: params.entity_id ? [params.entity_id] : this.accessibleEntities,
        filters
      }
    };
  }
}
