import React, { useState, Dispatch } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';

export default function Mvs({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [mvsPackage, setMvsPackage] = useState<{
    id: string;
    name: string;
    checks: { id: string; name: string }[];
  } | null>(null);
  const [checks, setChecks] = useState<{ id: string; name: string }[]>([]);

  const { data, isLoading } = useQuery({
    queryKey: ['mvs data'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getMvsData(application.id);
        return res;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting onboard express data, ${error}`,
          state: 'error'
        }
      })
  });

  const { mutate: mvsExport, isLoading: loadingMvsExport } = useMutation({
    mutationFn: async () => {
      if (application && mvsPackage) {
        const { res } = await Api.mvsExport(application.id, {
          package_id: mvsPackage.id,
          blueprint_ids: checks.map((c) => c.id)
        });
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in sending request to MVS, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.MVS}
      label="MVS export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>MVS request test</Stack>
        <Stack sx={{ flexDirection: 'row', marginTop: 3, columnGap: 4 }}>
          <Autocomplete
            disablePortal
            options={data?.packages || []}
            getOptionLabel={(option) => option.name}
            value={mvsPackage}
            sx={{ ...sharedClasses.formAutocomplete, width: '50%' }}
            loading={isLoading}
            loadingText="Loading packages..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Package"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
              />
            )}
            onChange={(event, value) => {
              if (value) {
                const packageChecks: { id: string; name: string }[] = [];
                value.checks.map((c) => {
                  const checkFound = data?.blueprints.find(
                    (b: { id: string; name: string }) => b.id === c.id
                  );
                  checkFound && packageChecks.push(checkFound);
                });
                setChecks(packageChecks);
              } else {
                setChecks([]);
              }
              setMvsPackage(value);
            }}
          />
          <Autocomplete
            multiple
            disablePortal
            options={data?.blueprints || []}
            getOptionLabel={(option) => option.name}
            value={checks}
            sx={{ ...sharedClasses.formAutocomplete, width: '50%' }}
            loading={isLoading}
            loadingText="Loading checks..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Additional Checks"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
              />
            )}
            onChange={(event, value) => setChecks(value)}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Send"
          primaryButtonCallback={mvsExport}
          isLoading={loadingMvsExport}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
