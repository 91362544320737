import React, { useState, Dispatch } from 'react';
import { Divider, Stack } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import { MultilineFormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { theme } from '../../../../../components/ThemeContext/ThemeObject';

export default function UnusualActivity({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen, referenceId, selectedReferee } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [comments, setComments] = useState<string>('');
  const [selectedButton, setSelectedButton] = useState<string>('');

  const buttonsColor = () => {
    switch (selectedButton) {
      case 'valid':
        return '#8CCF95';
      case 'suspicious':
        return theme.palette.error.main;
      default:
        return '#7CD0CC';
    }
  };

  const { mutate: resolveUnusualActivity, isLoading: resolvingUnusualActivity } = useMutation({
    mutationFn: async () => {
      if (application && referenceId && selectedReferee) {
        const { res } = await Api.resolveUnusualActivity(application.id, {
          referee_id: selectedReferee.id,
          note: comments,
          reference_id: referenceId,
          resolution: selectedButton ? selectedButton : undefined
        });
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
    },
    onError: (error: { message: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in resolving unusual activity, ${error.message}`,
          state: 'error'
        }
      });
    }
  });

  const handleClose = () => {
    dispatch({
      type: 'SET_UNUSUAL_ACTIVITY_DATA',
      payload: { referenceId: null, selectedReferee: null }
    });
    dispatch({ type: 'SET_MODALS_OPEN', payload: null });
  };

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.UNUSUAL_ACTIVITY}
      label="Unusual activity modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Unusual activity</Stack>
        <Stack
          sx={{
            border: '1px solid #7CD0CC',
            borderRadius: '6px',
            padding: '8px 16px'
          }}
        >
          <Stack
            sx={{ flexDirection: 'row', columnGap: 1, alignItems: 'center', color: '#939393' }}
          >
            <InfoIcon fontSize="small" sx={{ color: '#7CD0CC' }} />
            Status:
            <Stack sx={{ fontWeight: 'bold' }}>{selectedReferee?.unusual_activities?.status}</Stack>
          </Stack>
        </Stack>
        <Stack sx={{ rowGap: 1 }}>
          <Stack sx={{ color: '#838383' }}>Verify this activity</Stack>
          <Stack
            sx={{
              ...styles.verifyActivityButtonsContainer,
              border: `2px solid ${buttonsColor()}`,
              color: buttonsColor()
            }}
          >
            <Stack
              sx={{
                ...styles.verifyActivityButton,
                background: selectedButton === 'valid' ? '#EFFDF3' : 'inherit',
                ...(selectedButton === 'suspicious' && { color: '#93939390' })
              }}
              onClick={() =>
                selectedButton === 'valid' ? setSelectedButton('') : setSelectedButton('valid')
              }
            >
              Valid
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ margin: 0.25, borderColor: `${buttonsColor()}25`, borderRightWidth: 1 }}
            />
            <Stack
              sx={{
                ...styles.verifyActivityButton,
                background: selectedButton === 'suspicious' ? `#FCEFEF` : 'inherit',
                ...(selectedButton === 'valid' && { color: '#93939390' })
              }}
              onClick={() =>
                selectedButton === 'suspicious'
                  ? setSelectedButton('')
                  : setSelectedButton('suspicious')
              }
            >
              Suspicious
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: '50%' }}>
          <MultilineFormTextField
            label="Comments"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            rows={6}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Submit"
          primaryButtonCallback={resolveUnusualActivity}
          isLoading={resolvingUnusualActivity}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
